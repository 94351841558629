import Layout from "@ui/layout";
import HomeHeader from "@views/Home/components/HomeHeader";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Layout
      seo={{
        title: "Privacy Policy - Reactemplates.com",
        description:
          "Learn how Reactemplates.com collects, uses, and protects your personal information in compliance with data protection regulations.",
      }}
    >
      <div className="container mx-auto px-4 py-8 max-w-4xl mt-10">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
        <p className="italic text-gray-600 mb-8">
          <strong>Last Updated:</strong> March 1, 2025
        </p>

        <p className="mb-4">
          Welcome to Reactemplates.com. This Privacy Policy explains
          how we collect, use, disclose, and safeguard your
          information when you visit our website or use our services.
        </p>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            1. Information We Collect
          </h2>

          <h3 className="text-xl font-semibold mt-4 mb-2">
            1.1 Personal Information
          </h3>
          <p className="mb-2">
            We may collect personal information that you voluntarily
            provide to us when you:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">Register for an account</li>
            <li className="mb-2">Purchase products or services</li>
            <li className="mb-2">Subscribe to our newsletter</li>
            <li className="mb-2">Contact our customer support</li>
            <li className="mb-2">
              Participate in surveys or promotions
            </li>
          </ul>
          <p className="mb-2">This information may include:</p>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">Name</li>
            <li className="mb-2">Email address</li>
            <li className="mb-2">Billing information</li>
            <li className="mb-2">Company name</li>
            <li className="mb-2">Job title</li>
          </ul>

          <h3 className="text-xl font-semibold mt-4 mb-2">
            1.2 Automatically Collected Information
          </h3>
          <p className="mb-2">
            When you visit our website, we may automatically collect
            certain information about your device and usage,
            including:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">IP address</li>
            <li className="mb-2">Browser type and version</li>
            <li className="mb-2">Operating system</li>
            <li className="mb-2">Referring website</li>
            <li className="mb-2">Pages you view</li>
            <li className="mb-2">Time spent on pages</li>
            <li className="mb-2">Links you click</li>
            <li className="mb-2">Search terms used</li>
          </ul>

          <h3 className="text-xl font-semibold mt-4 mb-2">
            1.3 Cookies and Similar Technologies
          </h3>
          <p className="mb-2">
            We use cookies and similar tracking technologies to
            collect and track information about your browsing
            activities. These technologies help us:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">Remember your preferences</li>
            <li className="mb-2">
              Understand how you use our website
            </li>
            <li className="mb-2">Personalize your experience</li>
            <li className="mb-2">
              Analyze the effectiveness of our marketing
            </li>
            <li className="mb-2">Improve our services</li>
          </ul>
          <p className="mb-2">
            You can control cookies through your browser settings.
            However, disabling cookies may limit your ability to use
            certain features of our website.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            2. How We Use Your Information
          </h2>
          <p className="mb-2">
            We may use the information we collect for various
            purposes, including to:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">
              Provide, maintain, and improve our services
            </li>
            <li className="mb-2">
              Process transactions and send transaction notifications
            </li>
            <li className="mb-2">
              Send administrative information, such as updates to our
              terms and policies
            </li>
            <li className="mb-2">
              Respond to your comments, questions, and requests
            </li>
            <li className="mb-2">
              Send promotional communications, such as special offers
              or newsletters
            </li>
            <li className="mb-2">
              Monitor and analyze trends, usage, and activities
            </li>
            <li className="mb-2">
              Detect, prevent, and address technical issues
            </li>
            <li className="mb-2">Enforce our terms and policies</li>
          </ul>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            3. How We Share Your Information
          </h2>
          <p className="mb-2">We may share your information with:</p>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">
              <strong>Service Providers:</strong> Third-party vendors
              who perform services on our behalf, such as payment
              processing, data analysis, email delivery, hosting, and
              customer service.
            </li>
            <li className="mb-2">
              <strong>Business Partners:</strong> We may share your
              information with business partners to offer certain
              products, services, or promotions.
            </li>
            <li className="mb-2">
              <strong>Legal Requirements:</strong> We may disclose
              your information if required to do so by law or in
              response to valid requests by public authorities.
            </li>
            <li className="mb-2">
              <strong>Business Transfers:</strong> In connection with
              any merger, sale of company assets, financing, or
              acquisition of all or a portion of our business by
              another company.
            </li>
            <li className="mb-2">
              <strong>With Your Consent:</strong> We may share your
              information for any other purpose with your consent.
            </li>
          </ul>
          <p className="mb-2">
            We do not sell your personal information to third parties.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            4. Data Security
          </h2>
          <p className="mb-2">
            We implement appropriate technical and organizational
            measures to protect your personal information against
            unauthorized access, alteration, disclosure, or
            destruction. However, no method of transmission over the
            Internet or electronic storage is 100% secure, and we
            cannot guarantee absolute security.
          </p>
          <p className="mb-2">
            You are responsible for maintaining the confidentiality of
            your account credentials and for restricting access to
            your computer or device. You should be aware that there
            are always risks associated with providing personal
            information online.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            5. Your Data Protection Rights
          </h2>
          <p className="mb-2">
            Depending on your location, you may have certain rights
            regarding your personal information, including:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">
              <strong>Access:</strong> You have the right to request
              copies of your personal information.
            </li>
            <li className="mb-2">
              <strong>Rectification:</strong> You have the right to
              request that we correct any information you believe is
              inaccurate or complete information you believe is
              incomplete.
            </li>
            <li className="mb-2">
              <strong>Erasure:</strong> You have the right to request
              that we erase your personal information, under certain
              conditions.
            </li>
            <li className="mb-2">
              <strong>Restriction:</strong> You have the right to
              request that we restrict the processing of your personal
              information, under certain conditions.
            </li>
            <li className="mb-2">
              <strong>Object:</strong> You have the right to object to
              our processing of your personal information, under
              certain conditions.
            </li>
            <li className="mb-2">
              <strong>Data Portability:</strong> You have the right to
              request that we transfer the data we have collected to
              another organization, or directly to you, under certain
              conditions.
            </li>
          </ul>
          <p className="mb-2">
            To exercise any of these rights, please contact us using
            the information provided in the "Contact Us" section
            below.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            6. Children's Privacy
          </h2>
          <p className="mb-2">
            Our website and services are not intended for individuals
            under the age of 16. We do not knowingly collect personal
            information from children under 16. If we discover that a
            child under 16 has provided us with personal information,
            we will promptly delete it from our systems. If you
            believe we might have any information from or about a
            child under 16, please contact us immediately.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            7. International Data Transfers
          </h2>
          <p className="mb-2">
            We may transfer, store, and process your information in
            countries other than your own. Our servers and third-party
            service providers may be located in countries outside your
            jurisdiction, where data protection laws may differ from
            those in your country.
          </p>
          <p className="mb-2">
            By providing us with your information, you acknowledge and
            consent to this transfer, storing, or processing. We will
            take all steps reasonably necessary to ensure your data is
            treated securely and in accordance with this Privacy
            Policy.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            8. Third-Party Links
          </h2>
          <p className="mb-2">
            Our website may contain links to third-party websites and
            services. We have no control over the content, privacy
            policies, or practices of these third parties. We
            encourage you to review the privacy policies of any
            third-party sites you visit.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            9. Updates to This Privacy Policy
          </h2>
          <p className="mb-2">
            We may update this Privacy Policy from time to time to
            reflect changes in our practices or for other operational,
            legal, or regulatory reasons. We will notify you of any
            material changes by posting the new Privacy Policy on this
            page and updating the "Last Updated" date at the top of
            this policy.
          </p>
          <p className="mb-2">
            We encourage you to review this Privacy Policy
            periodically to stay informed about how we are protecting
            your information.
          </p>
        </div>

        <div className="mb-8 p-4 bg-gray-100 rounded-lg">
          <h2 className="text-2xl font-bold mb-4">10. Contact Us</h2>
          <p className="mb-2">
            If you have any questions or concerns about this Privacy
            Policy or our data practices, please contact us at:
          </p>
          <p>
            <strong>Email:</strong> [privacy@reactemplates.com]
            <br />
            <strong>Address:</strong> [Your Company Address]
            <br />
            <strong>Data Protection Officer:</strong> [Name/Email if
            applicable]
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            11. California Privacy Rights
          </h2>
          <p className="mb-2">
            If you are a California resident, you have specific rights
            regarding your personal information under the California
            Consumer Privacy Act (CCPA). These rights include:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">
              The right to know what personal information we collect,
              use, disclose, and sell
            </li>
            <li className="mb-2">
              The right to request deletion of your personal
              information
            </li>
            <li className="mb-2">
              The right to opt-out of the sale of your personal
              information
            </li>
            <li className="mb-2">
              The right to non-discrimination for exercising your CCPA
              rights
            </li>
          </ul>
          <p className="mb-2">
            To exercise your CCPA rights, please contact us using the
            information in the "Contact Us" section.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mt-8 mb-4">
            12. EU/EEA Users (GDPR Compliance)
          </h2>
          <p className="mb-2">
            If you are in the European Union or European Economic
            Area, we process your personal data according to the
            principles of the General Data Protection Regulation
            (GDPR).
          </p>
          <p className="mb-2">
            The legal bases we rely on for processing your personal
            information include:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">
              <strong>Consent:</strong> You have given clear consent
              for us to process your personal information for a
              specific purpose.
            </li>
            <li className="mb-2">
              <strong>Contract:</strong> Processing is necessary for a
              contract we have with you, or because you have asked us
              to take specific steps before entering into a contract.
            </li>
            <li className="mb-2">
              <strong>Legitimate Interests:</strong> Processing is
              necessary for our legitimate interests or the legitimate
              interests of a third party, provided those interests do
              not outweigh your rights and freedoms.
            </li>
            <li className="mb-2">
              <strong>Legal Obligation:</strong> Processing is
              necessary for us to comply with the law.
            </li>
          </ul>
          <p className="mb-2">
            In addition to the rights outlined in Section 5, EU/EEA
            residents also have the right to lodge a complaint with a
            supervisory authority in their country of residence.
          </p>
        </div>

        <hr className="my-8" />

        <p>
          By using Reactemplates.com, you acknowledge that you have
          read and understood this Privacy Policy.
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
